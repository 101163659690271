import React, { useEffect } from 'react';
import ContactForm from '../../Components/ContactForm/ContactForm';
import Banner from '../../Components/Shared/Banner';
import JoinCommunity from '../../Components/Shared/JoinCommunity';
import { Helmet } from "react-helmet";

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add("addpageBG");
        document.title = "Connect | IMMERSO";
    }, []);


    return (
        <>
            <Helmet>

                <title>Immerso Community - Contact Us</title>

                <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/connect`} />

                <meta name="description" content="Join the Global Immerso Community and follow us across all our Social Media platforms." />

                <meta property="og:url" content="https://immerso.be/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Immerso Community - Contact Us" />
                <meta property="og:description" content="Join the Global Immerso Community and follow us across all our Social Media platforms." />
                <meta property="og:image" content="https://immerso.be/assets/img/logo.webp"></meta>

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="immerso.be" />
                <meta property="twitter:url" content="https://immerso.be/" />
                <meta name="twitter:title" content="Immerso Community - Contact Us" />
                <meta name="twitter:description" content="Join the Global Immerso Community and follow us across all our Social Media platforms." />
                <meta name="twitter:image" content="https://immerso.be/assets/img/logo.webp"></meta>

            </Helmet>
            <Banner bgImage={"assets/img/contact_banner.webp"} joinImmerso={true} />
            <JoinCommunity />
            <section className='contact_us section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8 offset-md-2'>
                            <h2 className='section_heading font_futura textHighlightDark text-center mb-5'>Contact Us</h2>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact