import React from 'react';

const JoinCommunity = () => {
    return (
        <>
            <section className='join_our_community section_padding add_ovalSeperator'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='join_our_community_cont'>
                                <h1 className='section_heading font_futura textHighlightDark text-center mb-5'>Join Our Community</h1>
                            </div>
                            <div className='join_our_community_social_outer'>
                                <ul className='list-unstyled p-0 m-0 d-flex flex-wrap justify-content-center'>
                                    <li>
                                        <a href="https://twitter.com/immersoofficial?s=11&t=Eqzp8dc6B5pDDF7lw_01xg" target="_blank">
                                            <img src="assets/img/twitter.webp" alt="twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://discord.gg/immerso" target="_blank">
                                            <img src="assets/img/discord.webp" alt="discord" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/ImmersoOfficial" target="_blank">
                                            <img src="assets/img/telegram.webp" alt="telegram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/Immerso.be/" target="_blank">
                                            <img src="assets/img/facebook.webp" alt="facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://instagram.com/immerso.official?igshid=YmMyMTA2M2Y=" target="_blank">
                                            <img src="assets/img/instagram.webp" alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://immerso.medium.com/" target="_blank">
                                            <img src="assets/img/medium-brands.webp" alt="medium-brands" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/immersoofficial" target="_blank">
                                            <img src="assets/img/linkedIn.webp" alt="linkedIn" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCuwTIb4EB0ro5s89NbS-1SQ" target="_blank">
                                            <img src="assets/img/youtube.webp" alt="youtube" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JoinCommunity