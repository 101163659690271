/** @format */

import React, { useEffect } from "react";
import classes from "../ComingSoon/ComingSoon.module.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NotFound = () => {

    useEffect(() => {
        document.body.classList.add("addpageBG");
    }, []);

    return (
        <>
            <Helmet>
                <title>Not Found | IMMERSO</title>
            </Helmet>
            <div className={classes.NotFoundOuter}>
                <div className={classes.OuterContainer}>
                    <div className={classes.InnerContainer}>404 NOT FOUND</div>
                    <div className="d-table mx-auto mt-5">
                        <Link to="/home" className="btn btn-outline-primary">Go to home</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
