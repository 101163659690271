import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Banner = ({ bgImage, joinImmerso, setPosition }) => {

    const [loading, setLoading] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [notSubmitted, setNotSubmitted] = useState(false);

    const [recaptchaAlert, setRecaptchaAlert] = useState(false);

    const recaptchaRef = React.createRef();

    const {
        register,
        handleSubmit,
        reset,
        formState: {
            errors
        }
    } = useForm({
        mode: "onTouched"
    });

    const newsLetterSubmit = async (data) => {

        const token = await recaptchaRef.current.getValue();

        if (!token) {
            setRecaptchaAlert(true);
            setTimeout(() => {
                setRecaptchaAlert(false);
            }, 10000);
            return false;
        }

        try {

            setLoading(true);

            const { newsletterEmail } = data;

            let getData = await axios.post(`${process.env.REACT_APP_IMMERSO_URL}/api/save-newsletter`, {
                newsletterEmail
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            let submittedData = await getData;

            window.grecaptcha.reset();
            setRecaptchaAlert(false);

            if (submittedData.data.success) {

                let sendNewsletterMail = await axios.post(`${process.env.REACT_APP_IMMERSO_URL}/api/mail-newsletter`, {
                    newsletterEmail
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                let getSendNewsletterMail = await sendNewsletterMail;

                if (getSendNewsletterMail.data.data.status) {


                    setLoading(false);

                    setSubmitted(true);

                    setTimeout(() => {
                        setSubmitted(false);
                    }, 10000);

                    reset();
                }

            } else {

                setLoading(false);
                setNotSubmitted(true);

                setTimeout(() => {
                    setNotSubmitted(false);
                }, 10000);

                reset();

            }


        } catch (error) {
            window.grecaptcha.reset();
            setRecaptchaAlert(false);
            reset();
            setLoading(false);
            setNotSubmitted(true);

            setTimeout(() => {
                setNotSubmitted(false);
            }, 10000);

        }
    }

    return (
        <>
            <section className={`banner_section${joinImmerso ? " has-newsletter d-flex flex-wrap justify-content-center align-items-center" : ""}`} style={{ background: `url(${bgImage}) no-repeat`, backgroundSize: 'cover', backgroundPosition: setPosition ? setPosition : "initial" }}>
                {joinImmerso && (
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='join_immerso d-flex flex-column flex-wrap
                                 mx-auto'>
                                    <h2 className='text-center headingStyleBold mb-4 pb-2'>Join Immerso</h2>
                                    <div className='custom_form_style'>
                                        <form onSubmit={handleSubmit(newsLetterSubmit)}>
                                            <div className="form-group position-relative inline_btn_form">
                                                <input type="email" placeholder="Enter your email" className="form-control" {...register("newsletterEmail", {
                                                    required: "This field is required",
                                                    pattern: {
                                                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                        message: "Please enter a valid email Address"
                                                    }
                                                })} />
                                                <button type="submit" className="btn submitStyleBtn">Register</button>
                                            </div>

                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey="6LdsdachAAAAAJdmLEJ-JGdPeLyeav7qqTedxOoi"
                                                className="recaptcha_outer"
                                            />

                                            {errors.newsletterEmail && <div className='alert mt-3 alert-danger py-2 px-3 m-0'>{errors.newsletterEmail.message}</div>}

                                            {loading && <div className='alert mt-3 alert-warning py-2 px-3 m-0'>Submitting...</div>}

                                            {submitted && <div className='alert mt-3 alert-success py-2 px-3 m-0'>Thank you for subscribing!</div>}

                                            {notSubmitted && <div className='alert mt-3 alert-danger py-2 px-3 m-0'>Something went wrong. Please try again later.</div>}

                                            {recaptchaAlert && <div className='alert mt-3 alert-danger py-2 px-3 m-0'>Please verify that you're not a robot by solving captcha before submitting.</div>}

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    )
}

export default Banner;