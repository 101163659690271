/** @format */

import React, { useState } from "react";
import classes from "./Trailer.module.css";
import { motion } from "framer-motion";

const Trailer = ({ showSkipButton = false, handleClick, displayYTVideo }) => {

  const [videoLanguage, setVideoLanguage] = useState("https://www.youtube.com/embed/XrfCdKNRp6E");

  const changeVideo = (e) => {

    let choosedLanguage = e.target.value;

    if (choosedLanguage === "korean") {

      setVideoLanguage("https://www.youtube.com/embed/pa08u1RcL78");

    } else if (choosedLanguage === "filipino") {

      setVideoLanguage("https://www.youtube.com/embed/MzJwjb1Tkeg");

    } else if (choosedLanguage === "vietnamese") {

      setVideoLanguage("https://www.youtube.com/embed/oo_iffA0FO8");

    } else if (choosedLanguage === "arabic") {

      setVideoLanguage("https://www.youtube.com/embed/FZ5TcknmH94");

    } else if (choosedLanguage === "turkish") {

      setVideoLanguage("https://www.youtube.com/embed/RV_bvywMyoY");

    } else if (choosedLanguage === "mandarin") {

      setVideoLanguage("https://www.youtube.com/embed/2PNz0_tjdKI");

    }
    else if (choosedLanguage === "hindi") {
      setVideoLanguage("https://www.youtube.com/embed/jgp-LngMSDE");
    }
    else {

      setVideoLanguage("https://www.youtube.com/embed/XrfCdKNRp6E");

    }

  }

  return (
    <motion.div
      className={classes.OuterContainer}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 1,
      }}
    >
      <div className={classes.InnerContainer}>
        <div className="popup_cont d-flex flex-wrap justify-content-center align-items-center">
          <div className="popup_cont_inner position-relative bg-black">

            {
              displayYTVideo ? <>
                <div className="choose_video_language">
                  <div className="custom_form_style custom_fields_small">
                    <form>
                      <div className="form-group d-flex flex-wrap align-items-center">
                        <label htmlFor="chooseVideoLanguage" className="form-label text-white mb-0">Choose Language</label>
                        <select id="chooseVideoLanguage" className="form-select" onChange={changeVideo}>
                          <option value="english">English</option>
                          <option value="hindi">Hindi</option>
                          <option value="arabic">Arabic</option>
                          <option value="filipino">Filipino</option>
                          <option value="korean">Korean</option>
                          <option value="mandarin">Mandarin</option>
                          <option value="turkish">Turkish</option>
                          <option value="vietnamese">Vietnamese</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <iframe
                  src={videoLanguage}
                  title="Trailer"
                  allowFullScreen
                />
              </> : <iframe
                src={videoLanguage}
                title="Trailer"
                allowFullScreen
              />
            }

            {/* <video controls={true} autoPlay>
                <source src="assets/videos/Brahmand_Teaser_11.mp4" type="video/mp4" />
              </video> */}

            {showSkipButton && (
              <button onClick={handleClick} className="btn btn-close">
                <i className="material-symbols-outlined">close</i>
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Trailer;
