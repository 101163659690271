/** @format */

import React, { useEffect } from "react";
import classes from "./ComingSoon.module.css";
import { Helmet } from "react-helmet";

const ComingSoon = () => {

  useEffect(() => {
    document.body.classList.add("addpageBG");
  }, []);

  return (
    <>
      <Helmet>
        <title>Coming Soon | IMMERSO</title>
        <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/coming-soon`} />
      </Helmet>
      <div className={classes.OuterContainer}>
        <div className={classes.InnerContainer}>COMING  SOON</div>
      </div>
    </>
  );
};

export default ComingSoon;
