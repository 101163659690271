/** @format */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
import classes from "./Sidebar.module.css";
import Cross from "../../../assets/Navbar_Footer/Cross.svg";
import SidebarLine from "../../../assets/Navbar_Footer/SidebarLine.svg";


function Sidebar({ sideBar, setSideBar, imageShow }) {

  const [buttonText, setbuttonText] = useState("Play Now");

  const HoverButton = () => {
    if (buttonText === "Play Now") {
      setbuttonText("Coming Soon");
    }
    if (buttonText === "Coming Soon") {
      setbuttonText("Play Now");
    }
  };

  useEffect(() => {
    if (sideBar) {
      document.body.classList.add("overflow-hidden");
      return function cleanup() {
        document.body.classList.remove("overflow-hidden");
      };
    }

  }, [sideBar]);
  return (
    <AnimatePresence>
      {sideBar && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: "0%",
            }}
            exit={{
              x: "150%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 1 }}
            className={classes.Container}>

            <div className={classes.Links}>
              <div onClick={() => setSideBar(false)} className={classes.Close}>
                <img src={Cross} alt='Cross' />
              </div>


              <div className="mobile_header_menus">
                <div className="text-center">
                  <img src={SidebarLine} alt='SidebarLine' />
                </div>
                <ul className="navbar-nav text-center">
                  <li className="nav-item">
                    <NavLink onClick={() => setSideBar(false)} to="/" className="nav-link">Home</NavLink>
                  </li>
                  <li>
                    <div>
                      <img src={SidebarLine} alt='SidebarLine' />
                    </div>
                  </li>
                  <li className="nav-item overflow-hidden">
                    <NavLink to="/about" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">About</NavLink>
                    <ul className="dropdown-menu">
                      <li><Link onClick={() => setSideBar(false)} to="/the-ecosystem" className="dropdown-item">The Eco-system</Link></li>
                      <li><Link onClick={() => setSideBar(false)} to="/immerso-virtual-universe" className="dropdown-item">Immerso virtual universe</Link></li>
                      <li><Link onClick={() => setSideBar(false)} to="/immerso-story" className="dropdown-item">Immerso Story</Link></li>
                      {/* <li><Link onClick={() => setSideBar(false)} to="/faq" className="dropdown-item">FAQ</Link></li> */}
                    </ul>
                  </li>
                  <li>
                    <div>
                      <img src={SidebarLine} alt='SidebarLine' />
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink onClick={() => setSideBar(false)} to="/media" className="nav-link">Media</NavLink>
                  </li>
                  <li>
                    <div>
                      <img src={SidebarLine} alt='SidebarLine' />
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink onClick={() => setSideBar(false)} to="/coming-soon" className="nav-link">Team</NavLink>
                  </li>
                  <li>
                    <div>
                      <img src={SidebarLine} alt='SidebarLine' />
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink onClick={() => setSideBar(false)} to="/connect" className="nav-link">Connect</NavLink>
                  </li>
                  <li>
                    <div>
                      <img src={SidebarLine} alt='SidebarLine' />
                    </div>
                  </li>
                </ul>
                {imageShow && (
                  <div className="site_header_btns text-center">
                    {/* <button
                      className="small_btn">
                      Register
                    </button> */}
                    <button
                      onMouseEnter={HoverButton}
                      onMouseLeave={HoverButton}
                      className="small_btn">
                      {buttonText}
                    </button>
                  </div>
                )}
              </div>

              {/* <Link onClick={() => setSideBar(false)} to='/home'>
                <div className={classes.link}>Home</div>
              </Link>
              <div>
                <img src={SidebarLine} alt='SidebarLine' />
              </div>
              <Link onClick={() => setSideBar(false)} to='/about'>
                <div className={classes.link}>About</div>
              </Link>
              <div>
                <img src={SidebarLine} alt='SidebarLine' />
              </div>
              <Link onClick={() => setSideBar(false)} to='/docs'>
                <div className={classes.link}>Docs</div>
              </Link>
              <div>
                <img src={SidebarLine} alt='SidebarLine' />
              </div>
              <Link onClick={() => setSideBar(false)} to='/team'>
                <div className={classes.link}>Team</div>
              </Link>
              <div>
                <img src={SidebarLine} alt='SidebarLine' />
              </div>
              <Link onClick={() => setSideBar(false)} to='/faq'>
                <div className={classes.link}>FAQ</div>
              </Link>
              <div>
                <img src={SidebarLine} alt='SidebarLine' />
              </div> */}
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setSideBar(false)}
            className={classes.backdrop}
          />
        </>
      )}
    </AnimatePresence>
  );
}

export default Sidebar;
