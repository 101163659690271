import React from 'react';

const Slider = ({ bannerCount, children }) => {
    return (
        <>
            <section className='slider'>
                <div id="carouselSliderIndicators" className="carousel slide" data-bs-ride="true">
                    <div className="carousel-indicators">
                        {bannerCount.map((allBannerCount, allBannerCountNumber) => {
                            return (
                                <React.Fragment key={allBannerCountNumber}>
                                    <button type="button" data-bs-target="#carouselSliderIndicators" data-bs-slide-to={allBannerCountNumber} className={`${allBannerCountNumber === 0 ? "active" : ""}`} aria-current={`${allBannerCount === 0 ? true : ""}`} aria-label={`Slide ${allBannerCount}`}>
                                    </button>
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className="carousel-inner">
                        {children}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselSliderIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                            <img src="assets/img/iconLeft.webp" alt="arrow" />
                        </span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselSliderIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true">
                            <img src="assets/img/iconRight.webp" alt="arrow" />
                        </span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>
        </>
    )
}

export default Slider;