/** @format */

import React from "react";
import classes from "./Footer.module.css";
import Telegram from "../../assets/Navbar_Footer/Telegram.svg";
import Discord from "../../assets/Navbar_Footer/Discord.svg";
import Twitter from "../../assets/Navbar_Footer/Twitter.svg";
// import Space from "../space/Space";

const Footer = () => {
  return (
    <div className={classes.Footer}>
      <div className={classes.Socials}>
        <div className={classes.socialGrp}>
          <div>Join Our Community</div>
          <div className="footer_social_icons">
            <ul className="list-unstyled p-0 m-0 d-flex flex-wrap">
              <li>
                <a href="https://twitter.com/immersoofficial?s=11&t=Eqzp8dc6B5pDDF7lw_01xg" target="_blank"><img src={Twitter} alt='Twitter' /></a>
              </li>
              <li>
                <a href="https://discord.gg/immerso" target="_blank"><img src={Discord} alt='Discord' /></a>
              </li>
              <li>
                <a href="https://t.me/ImmersoOfficial" target="_blank"><img src={Telegram} alt='Telegram' /></a>
              </li>
              <li>
                <a href="https://m.facebook.com/Immerso.be/" target="_blank"><img src={`${window.location.origin}/assets/img/facebook_small.svg`} alt="facebook" /></a>
              </li>
              <li>
                <a href="https://instagram.com/immerso.official?igshid=YmMyMTA2M2Y=" target="_blank">
                  <img src={`${window.location.origin}/assets/img/instagram_small.svg`} alt="instagram" />
                </a>
              </li>
              <li>
                <a href="https://immerso.medium.com/" target="_blank">
                  <img src={`${window.location.origin}/assets/img/medium-brands_small.svg`} alt="medium-brands" /></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/immersoofficial" target="_blank">

                  <img src={`${window.location.origin}/assets/img/linkedIn_small.svg`} alt="linkedIn_small" /></a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCuwTIb4EB0ro5s89NbS-1SQ" target="_blank">
                  <img src={`${window.location.origin}/assets/img/youtube_small.svg`} alt="youtube_small" />
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div className={classes.Community}>
        <div className={classes.Text}>
          2022 Immerso by Eros Investments. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
