/** @format */

import React, { useEffect } from "react";
//import classes from "./Common.module.css";
import Banner from "../../Components/Shared/Banner";
import { Helmet } from "react-helmet";

const TheEcoSystem = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add("addpageBG");
    }, []);

    return (
        <>
            <Helmet>

                <title>Immerso Eco-System | Virtual Universe | Web 3.0 Ecosystem</title>

                <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/the-ecosystem`} />

                <meta name="description" content="Within the Immerso eco-system, users can define their unique adventures and ‘experience economy’ in real-time. Explore our website to know the goal that elevates the world of entertainment through innovation and diversity." />

                <meta property="og:url" content="https://immerso.be/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Immerso Eco-System | Virtual Universe | Web 3.0 Ecosystem" />
                <meta property="og:description" content="Within the Immerso eco-system, users can define their unique adventures and ‘experience economy’ in real-time. Explore our website to know the goal that elevates the world of entertainment through innovation and diversity." />
                <meta property="og:image" content="https://immerso.be/assets/img/logo.webp"></meta>

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="immerso.be" />
                <meta property="twitter:url" content="https://immerso.be/" />
                <meta name="twitter:title" content="Immerso Eco-System | Virtual Universe | Web 3.0 Ecosystem" />
                <meta name="twitter:description" content="Within the Immerso eco-system, users can define their unique adventures and ‘experience economy’ in real-time. Explore our website to know the goal that elevates the world of entertainment through innovation and diversity." />
                <meta name="twitter:image" content="https://immerso.be/assets/img/logo.webp"></meta>

            </Helmet>
            {/* <div className={classes.Container}>
        <div className={classes.WorldHero} />
        <div className={classes.Heading}>Brahmand: The Virtual World</div>
        <div className={classes.Text}>
          The universe as we know it is an intergalactic infinity of time and
          space; however, according to ancient Indian history and mythology, the
          universe is home to 14 realms or ‘Lokas’ or planetary systems. It is
          believed that the elder god, Brahma, infused and blessed the universe
          with all forms of life and energies. It is this underlying ideology that
          has paved the way for both the architectural and visual development of
          the Brahmand virtual world.
        </div>
        <div className={classes.Text}>
          Within this techno-mythic expanse resides Dwarka, a terrestrial empire,
          simulating signatory styles and boasting untold treasures, such as
          weapons, warships, and armour. By embedding ancient mythological IP and
          narrative throughout the Brahmand world, users can fully immerse
          themselves in unique environments whilst expanding their existence
          through internal gameplay. Exploration begins at the Metaport with
          initial realm hopping, followed by space travel as you obtain the
          necessary assets from questing, PVM/PVP, trading, and other interactive
          experiences.
        </div>
        <div className={classes.Text}>
          Countless live, build, earn and play opportunities will be delegated to
          respective virtual worlds in the network. The only limit is the
          builder's imagination, as multiple virtual worlds on the network will
          have different themes, and functionalities as decided or delegated by
          the creator community. For example, in Brahmand World, users can play
          and use their earned rewards to upgrade their lives and assets,
          including purchasing properties, vehicles, avatars, and upgrades for
          their pre-existing assets. Upgrading assets will play an integral role
          in widening the scope of user participation on the Brahmand Network.
        </div>
      </div> */}
            <Banner bgImage="assets/img/atala.webp" />
            <section className="our_world_section section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="our_world_heading">
                                <h1 className="section_heading addHeadingSeparator font_futura textHighlightDark text-center mb-5">The Eco-System </h1>
                            </div>
                            <div className="our_world_cont colorOffWhite content_section">
                                {/* <p>
                                    Immerso is backed by Eros Investments Group, a venture with a four-decade legacy across the entertainment and technology sectors with assets in media, sports, blockchain, digital commerce and gaming. It’s portfolio companies
                                    <b>&nbsp;include&nbsp;
                                        <a rel="noreferrer" href="https://www.erosmediaworld.com/" className="textLink" target="_blank">Eros Media World</a>,&nbsp;

                                        <a rel="noreferrer" href="https://erosnow.com/" className="textLink" target="_blank">Eros Now</a>, <span className="fw-normal">and</span>&nbsp;

                                        <a rel="noreferrer" href="https://www.xfinite.io/" className="textLink" target="_blank">Xfinite’s</a>&nbsp;

                                        <a rel="noreferrer" href="https://www.mzaalo.com/" className="textLink" target="_blank">Mzaalo</a>.

                                    </b>
                                    Disruption is at the heart of Eros Investments, and its core culture is deeply rooted in next-generation intellectual properties with technology as the driving force. Its blockchain, sports, and entertainment ventures have been game changers with a solid first-mover advantage. The Eros Investments Group a future-ready organization committed to developing the Web 3.0 ecosystem with the mission to elevate the world of entertainment through innovation and diversity. With these synergies, Immerso has access to an unlimited global network of entertainment with renowned stories, celebrities, and massive community outreach with loyal followers. Within the Immerso eco-system, users can define their unique adventures through the ability to truly ‘experience economy’.
                                </p>

                                <p>
                                    The Immerso eco-system is powered by the <b>Virtual Universe</b> (Live, Play, Build, Earn), <b>original content creation</b>, and collaborations with celebrities, creators and different IPs. The underlying ideology of the universe is based on ancient mythology, layered with access to different IPs, celebrities and brands which will be leveraged as we scale the universe. Other planets will include Entertainment, Fashion, Divinity/Spiritual, Sports, and Music to name a few.  Within the Immerso virtual universe, ownership of assets and experiences will be established through NFT’s (Land, collectables, inventory items, exclusive airdrops). Our IP allows users to escape their known reality and freely immerse themselves in a fantasy world.
                                </p>

                                <p>The Immerso user (Immersians) will comprise of 3 different categories:<br /> </p>

                                <ol className="ps-3 mb-4 pb-2">
                                    <li> <b>Premium Active Users:</b> The active users are the creators and gamers on Immerso, they will be instrumental in creating this world economy including participation in the governance and scale of the ecosystem. They participate in real money and token economics, thereby gaining access to exclusive quality of life features, gameplay benefits and tangible say in the future direction of the world.</li>
                                    <li className="mt-2"><b>Selective User:</b> The selective user lives in this world for social interaction and recreational engagement in the metaverse. This user plays for fun and has access to all base gameplay features.</li>
                                    <li className="mt-2"><b>Observant User: </b> The observant user is traditionally more passive and will look more like a Web 2.0 user on the lean back and watch the economy.</li>
                                </ol>

                                <p>
                                    As users escape to enhance their virtual lives within Immerso, they will be eligible to delve further into the unravelling storylines across each realm and planets. Additionally, Immerso will facilitate meaningful virtual experiences and interactions between users and entertainment icons. The aspiration of users and celebrities will unite – where creators and users will be able to host and attend virtual gatherings, interact, play story-driven games, and engage with their favourite celebrities and influencers.
                                </p>

                                <p>Immerso facilitates every dreamer's desire to live out fantastical experiences across multiple environments, all whilst investing in their ‘virtual lives’. We invite you to build with us, creating a metaverse that delivers endlessly evolving exciting experiences, compounded by the network effect of an eco-system. Immerso is set to disrupt tradition, providing the integral foundation and infrastructure required for users, creators, and developers to seamlessly build within our decentralised virtual ecosystem.</p> */}


                                <p>Immerso is backed by Eros Investments Group, a powerhouse with a four-decade legacy in entertainment and technology. Their philosophy revolves around native intellectual property, blockchain technology, AI, and tokenization, all while keeping the community at its heart. The portfolio includes companies like <a rel="noreferrer" href="https://www.erosmediaworld.com/" className="textLink" target="_blank"><b>Eros Media World</b></a>, <a rel="noreferrer" href="https://erosnow.com/" className="textLink" target="_blank"><b>Eros Now</b></a>, and <a rel="noreferrer" href="https://www.xfinite.io/" className="textLink" target="_blank"><b>Xfinite</b></a>, all driven by innovation.</p>

                                <p>The Immerso ecosystem is built on original content creation, product development, and partnerships with celebrities, content creators, and IP owners. It covers a wide range of experiences, from gaming and entertainment to fashion, spirituality, sports, and music. True ownership of assets and experiences is established through tokenization, allowing users to engage with their favourite celebrities and influencers.</p>

                                <p>Immerso aims to disrupt traditional norms, offering the foundation and infrastructure for users, creators, and developers to seamlessly build within our decentralized virtual ecosystem. Join us in creating an ever-evolving metaverse filled with exciting experiences and a thriving community.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TheEcoSystem;
