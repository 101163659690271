/** @format */

import React, { useEffect } from "react";
//import classes from "./Common.module.css";
import Banner from "../../Components/Shared/Banner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ImmersoVirtualUniverse = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add("addpageBG");
    }, []);

    return (
        <>
            <Helmet>

                <title>Immerso Multi-Verse | Virtual World Gaming Community</title>

                <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/immerso-virtual-universe`} />

                <meta name="description" content="Immerso Multi-Verse seamlessly facilitates users to live, build, play, watch and own digital assets. Users around the world can join/create micro-communities within Immerso for gaming, socialising, shopping, building and more!" />

                <meta property="og:url" content="https://immerso.be/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Immerso Multi-Verse | Immerso Virtual Community" />
                <meta property="og:description" content="Immerso Multi-Verse seamlessly facilitates users to live, build, play, watch and own digital assets. Users around the world can join/create micro-communities within Immerso for gaming, socialising, shopping, building and more!" />
                <meta property="og:image" content="https://immerso.be/assets/img/logo.webp"></meta>

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="immerso.be" />
                <meta property="twitter:url" content="https://immerso.be/" />
                <meta name="twitter:title" content="Immerso Multi-Verse | Immerso Virtual Community" />
                <meta name="twitter:description" content="Immerso Multi-Verse seamlessly facilitates users to live, build, play, watch and own digital assets. Users around the world can join/create micro-communities within Immerso for gaming, socialising, shopping, building and more!" />
                <meta name="twitter:image" content="https://immerso.be/assets/img/logo.webp"></meta>

            </Helmet>
            {/* <div className={classes.Container}>
        <div className={classes.NetworkHero} />
        <div className={classes.Heading}>Our Network</div>
        <div className={classes.Text}>
          A network of virtual worlds on the Brahmand Network, accessed through a
          central ‘Metaport’ within the heart of Brahmand World, the first virtual
          world in existence; encompassed through an immersive 3D experience
          architecturally inspired by the ideology of infinite virtual expansion
          throughout the universe. Brahmand openly invites creators to build on
          the Brahmand Network and showcase their interpretation of a virtual
          world through pioneering interoperability, or alternatively build
          alongside the Brahmand core team within Brahmand World itself.
        </div>
      </div> */}
            <Banner bgImage="assets/img/content_banner.webp" setPosition="center" />
            <section className="our_network_section section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="our_network_heading">
                                <h1 className="section_heading addHeadingSeparator font_futura textHighlightDark text-center mb-5">Immerso Multi-Verse </h1>
                            </div>
                            <div className="our_network_cont colorOffWhite content_section">

                                {/* <p>Immerso is building a fully scalable virtual ecosystem, where the community is allured into a thriving universe and economy. We pave the way for the future of decentralized metaverse interaction, by which virtual microcosms of modern-day societies interchange on a local and global scale equivalent, allowing users to experience new and exciting ideologies. By providing the necessary infrastructure and technical capabilities, Immerso will seamlessly facilitate users to live, earn, build, play, watch, and own; engaging all communities and delivering a fully immersive user experience. Immerso’s economic decentralization ethos empowers users with verifiable ownership of digital assets accumulated on their adventures, enticing them to spend more time in the virtual universe.</p>

                                <p>The logic used to build the Immerso virtual world stipulates that the universe is split vertically into 14 realms, each containing planets. Our world-building begins on a planet in the Bhu-loka/central realm. The virtual world contains a city with endless opportunities for all users and is designed and dynamically developed by a core team with the intention of progressive decentralization to facilitate the creation of community lead projects. </p>

                                <p>Within the Immerso universe there are activities created by the core team including but not limited to social events and multiplayer experiences that cater to all audiences. Users can explore the open-world region and earn tokens through various Play-To-Earn mechanics. This currency can be used to enhance the virtual lives of users by purchasing and crafting decentralized assets. The multifaceted virtual universe expands vertically in the form of realms (lokas), with 6 upper and 7 lower realms, as well as horizontally through planetary expansion within each realm. Each of the realms has a unique purpose, significance and art style. There are other thematic planets being built at Bhuloka, which are not limited to Entertainment, Fashion, Divinity/Spiritual, Sports, and Music.<br />As you explore through the universe you can engage in social activities, complete quests, and upskill your merchant-focused assets and traits to build a superior and meaningful digital life. A range of assets from weapons to vehicles can be bought or forged from collected components to be used across the universe or monetized via de-fi marketplaces. </p>

                                <p>Creators and users can acquire virtual land within Immerso to build products and services for the Immerso community, host events, or even rent their land to other projects for profit. Relative to demand and engagement, the core team will strategically position limited supply third-party land and virtual planets in prime locations to maximise footfall and reach. As users enhance their virtual lives, their in-world avatars will evolve through the acquisition of these tradeable NFTs, encouraging users to explore and engage with every detailed inch of Immerso to benefit from cross-realm and planetary items. </p> */}

                                <p>Immerso offers a diverse range of universes, each designed for unique social, entertainment, and commerce experiences. These universes come with their own themes, art styles, and functionalities, all interconnected in a shared marketplace and integrated wallet.</p>

                                <p>With Immerso, you can enjoy:</p>
                                <ul className="ps-3 mb-4 pb-2">
                                    
                                <li>High-fidelity interactive experiences across platforms: mobile, PC, and VR/AR</li>
                                <li className="mt-2">Rich native IP inspired by ancient Indian mythology with a sci-fi/fantasy</li>
                                <li className="mt-2">Interactive experiences based on an extensive library of Indian language film IP from Eros</li>
                                <li className="mt-2">Exciting partnerships with global celebrities in entertainment, sports, music, and spirituality.</li>
                                <li className="mt-2">Virtual live events, including concerts, exclusive launches, fashion shows, and sports events.</li>
                                <li className="mt-2">Immersive e-commerce experiences featuring top global brands.</li>
                                <li className="mt-2">Ownership of digital assets across the ecosystem.</li>
                                </ul>
                                
                                <p>In Immerso Universes, you can play, create, socialize, watch, own, and monetize with IMSO, our platform token. You'll have control over avatars, characters, collectibles, virtual environments, and more through our decentralized content generation system. Immerso merges content, AI, and blockchain to create immersive experiences for communities, driven by IP, celebrity collaborations, and brand partnerships.</p>
                                


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ImmersoVirtualUniverse;
