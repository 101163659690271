/** @format */

import React, { useEffect, useState } from "react";
// import classes from "./Docs.module.css";
import Litepaper from "../../assets/Docs/Litepaper.png";
import Gaming_Economics from "../../assets/Docs/Gaming-Economics.png";
// import Video from "../../assets/Docs/Video.png";
// import Button from "../../assets/Docs/Download PDF.svg";
import Banner from "../../Components/Shared/Banner";
import CardCustom from "../../Components/CardCustom/CardCustom";
import { Helmet } from "react-helmet";

const Docs = () => {

  useEffect(() => {
    document.body.classList.add("addpageBG");
  }, []);

  return (
    <>
      <Helmet>

        <title>Immerso Media - In the News</title>

        <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/media`} />

        <meta name="description" content="For more info, check out our whitepaper, tokenomics or get all the info at Immerso.be" />

        <meta property="og:url" content="https://immerso.be/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Immerso Media - In the News" />
        <meta property="og:description" content="For more info, check out our whitepaper, tokenomics or get all the info at Immerso.be" />
        <meta property="og:image" content="https://immerso.be/assets/img/logo.webp"></meta>

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="immerso.be" />
        <meta property="twitter:url" content="https://immerso.be/" />
        <meta name="twitter:title" content="Immerso Media - In the News" />
        <meta name="twitter:description" content="For more info, check out our whitepaper, tokenomics or get all the info at Immerso.be" />
        <meta name="twitter:image" content="https://immerso.be/assets/img/logo.webp"></meta>

      </Helmet>
      {/* <div className={classes.Container}>
        <div className={classes.Litepaper}>
          <div>
            <p className={classes.Heading}>Litepaper</p>
            <img src={Button} alt='Button' />
          </div>
          <div className={classes.LitepaperImage}>
            <img src={Litepaper} alt='Litepaper' />
          </div>
        </div>
        <div className={classes.Gaming_Economics}>
          <div className={classes.GamingEconomicsImage}>
            <img src={Gaming_Economics} alt='Gaming_Economics' />
          </div>
          <div>
            <p className={classes.Heading}>Gaming Economics</p>
            <img src={Button} alt='Button' />
          </div>
        </div>
        <div className={classes.WatchVideo}>
          <div>
            <p className={classes.Heading}>Watch Video</p>
          </div>
          <div className={classes.VideoImage}>
            <img src={Video} alt='Litepaper' />
          </div>
        </div>
      </div> */}
      <Banner bgImage="assets/img/sutala.webp" setPosition="center" />
      <section className="media_section section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="media_section_heading">
                <h1 className="section_heading font_futura textHighlightDark text-center mb-5">Media</h1>
              </div>
              <div className="media_section_cont d-flex flex-wrap justify-content-between">
                <CardCustom cardImg="assets/img/video_thumb.webp" cardHeading="Trailer" cardBtnLinkText="Watch" comingSoonBtn={false} runVideo={true} />
                <CardCustom cardImg={Litepaper} cardHeading="Dynamic Paper" cardBtnLinkText="Read" comingSoonBtn={true} />
                <CardCustom cardImg={Gaming_Economics} cardHeading="Tokenomics" cardBtnLinkText="Read" comingSoonBtn={true} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Docs;
