import React, { useState, useEffect } from 'react';
import Slider from '../../Components/Shared/Slider';
import SlideItem from '../../Components/Shared/SlideItem';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Trailer from '../../Components/Trailer/Trailer';
import { useCookies } from 'react-cookie';

const Home = () => {

    const [cookies, setCookie] = useCookies(['homePopup']);
    const [opacity, setOpacity] = useState(1);
    const [videoModal, setvideoModal] = useState(false);

    const handleClick = () => {
        setOpacity(0);
        setvideoModal(false);
        let d = new Date();
        d.setTime(d.getTime() + (180 * 60 * 1000));
        setCookie('homePopup', true, { path: '/', expires: d });
    };

    useEffect(() => {
        document.body.classList.add("addpageBG");

        if (cookies.homePopup) {
            setvideoModal(false);
        } else {
            setOpacity(1);
            setvideoModal(true);
        }
    }, []);

    let bannerCount = [1, 2, 3, 4, 5, 6, 7];
    return (
        <>
            <Helmet>

                <title>Immerso Metaverse - Virtual Socialization & Engagement Ecosystem</title>

                <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/home`} />

                <meta name="description" content="Immerso is a metaverse project creating a virtual universe inspired by ancient mythology. Users can create avatars to represent themselves, play games, engage in social activities and much more." />

                <meta property="og:url" content="https://immerso.be/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Immerso - Virtual Socialization & Ecosystem | Virtual Environments" />
                <meta property="og:description" content="Immerso is a metaverse project creating a virtual universe inspired by ancient mythology. Users can create avatars to represent themselves, play games, engage in social activities and much more." />
                <meta property="og:image" content="https://immerso.be/assets/img/logo.webp"></meta>

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="immerso.be" />
                <meta property="twitter:url" content="https://immerso.be/" />
                <meta name="twitter:title" content="Immerso - Virtual Socialization & Ecosystem | Virtual Environments" />
                <meta name="twitter:description" content="Immerso is a metaverse project creating a virtual universe inspired by ancient mythology. Users can create avatars to represent themselves, play games, engage in social activities and much more." />
                <meta name="twitter:image" content="https://immerso.be/assets/img/logo.webp"></meta>

            </Helmet>
            <Slider bannerCount={bannerCount}>
                <SlideItem slideImage="assets/img/homeSlide1.webp" activeSlide={true} slideCaption="Janaloka" />
                <SlideItem slideImage="assets/img/patala.webp" activeSlide={false} slideCaption="Patala" />
                <SlideItem slideImage="assets/img/rasatala.webp" activeSlide={false} slideCaption="Rastala" />
                <SlideItem slideImage="assets/img/bhuloka.webp" activeSlide={false} slideCaption="Bhuloka" />
                <SlideItem slideImage="assets/img/kamboja.webp" activeSlide={false} slideCaption="Kamboja" />
                <SlideItem slideImage="assets/img/kuntibhoja.webp" activeSlide={false} slideCaption="Kuntibhoja" />
                <SlideItem slideImage="assets/img/mines-of-mangal.webp" activeSlide={false} slideCaption="Mines of Mangal" />
            </Slider>

            <section className='our_ecoSystem_section section_padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='our_ecoSystem_heading'>
                                <h1 className='section_heading addHeadingSeparator font_futura textHighlightDark text-center mb-5'>Experience Economy</h1>
                            </div>
                            <div className='our_ecoSystem_cont colorOffWhite content_section text-center'>
                                
                                {/* <p>The universe as we know it is an endless blanket of stars and vast galaxies, which continues to expand. A beautiful, limitless, gateway to undiscovered frontiers. Ancient scriptures discovered over 5000 years ago that our universe takes the form of an egg, consisting of 14 realms and a multitude of planets.</p>

                                <p>
                                    Immerso is an <Link to="/the-ecosystem" className='textLink'><b>eco-system</b> </Link>, creating a  <Link to="/immerso-virtual-universe" className='textLink'><b>virtual universe</b></Link> with social, story and entertainment-driven interactive experiences, imagined through the lens of sci-fi, adventure and fantasy.
                                </p>

                                <p>Within Immerso Universe, users can live, build, play, watch and earn to monetize their experiences through IMSO, the platform utility token. Experiences will include social activities, storyline questing, play-to-earn games based on rich lore and high-fidelity visuals, events and concerts featuring world-renowned celebrities. Users will be able to build and have ownership of virtual environments and different types of digital assets like collectables and characters through NFTs, as well as exclusive content viewing opportunities.</p>

                                <p>Immerso gives birth to a one-of-a-kind multi-user experience, where decentralised peer-to-peer interaction builds community and economy, paving the way for the future of virtual socialization and entertainment. </p> */}


                                <p>Immerso is a revolutionary cross-platform <Link to="/the-ecosystem" className='textLink'><b>eco-system</b> </Link> that's shaping the future of digital experiences. It's powered by IP and collaborations, blockchain, and AI, and developed by <a rel="noreferrer" href="https://www.erosinvest.com/" className="textLink" target="_blank"><b>Eros Investments</b></a>. </p>
                                
                                <p>With Immerso, you step into a virtual and extended reality <Link to="/immerso-virtual-universe" className='textLink'><b>multi-verse</b></Link> where you can create, socialize, play, watch, and own experiences. You can even monetize your adventures with IMSO, our platform token. Join our growing community and explore the limitless potential of tokenization, Web 3.0, and the open metaverse.</p>

                                
                                <div className='centerBtn'>
                                    <Link to="/the-ecosystem" className='btn btn-outline-primary'>Learn more</Link>
                                    {/* <button className='btn btn-outline-primary'>Learn more</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {videoModal && (
                <Trailer showSkipButton={true} displayYTVideo={true} handleClick={handleClick} />
            )}

        </>
    )
}

export default Home