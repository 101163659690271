/** @format */

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import AnimatedNavbar from "./Components/Navbar/AnimatedNavbar";
import Navbar from "./Components/Navbar/Navbar";
import About from "./Pages/About/About";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";
import Lokas from "./Pages/CTAs/Lokas";
import Network from "./Pages/CTAs/Network";
import ImmersoVirtualUniverse from "./Pages/CTAs/ImmersoVirtualUniverse";
import Story from "./Pages/CTAs/Story";
import World from "./Pages/CTAs/World";
import TheEcoSystem from "./Pages/CTAs/TheEcoSystem";
import Docs from "./Pages/Docs/Docs";
import FAQ from "./Pages/FAQ/FAQ";
import Home from "./Pages/Home/Home";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Team from "./Pages/Team/Team";
import Countdownpage from "./Pages/CountDownPage/Countdownpage";
import Contact from "./Pages/Contact/Contact";
import SubscriberListing from "./Pages/Newsletter/SubscriberListing";
import NotFound from "./Pages/404/NotFound";

const Wrapper = ({ Component, navbarImage = true, animatedNavbar = false }) => {
  return (
    <>
      {!animatedNavbar && <Navbar imageShow={navbarImage} />}
      <Component />
      <Footer />

    </>
  );
};

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Countdownpage />} />
        <Route path="/" element={<LandingPage />} /> */}
        {/* <Route path="/enter" element={<LandingPage />} /> */}
        {/* <Route path="/faq" element={<Wrapper Component={FAQ} />} /> */}
        <Route path="/team" element={<Wrapper Component={Team} />} />
        <Route path="/story" element={<Wrapper Component={Story} />} />
        {/* <Route
          path="/home"
          element={
            <Wrapper
              animatedNavbar={true}
              Component={Home}
              navbarImage={false}
            />
          }
        /> */}
        <Route path="/" element={<Wrapper Component={Home} />} />
        <Route path="/about" element={<Wrapper Component={About} />} />
        <Route
          path="/coming-soon"
          element={<Wrapper Component={ComingSoon} />}
        />
        {/* <Route path="/network" element={<Wrapper Component={Network} />} /> */}
        <Route path="/immerso-virtual-universe" element={<Wrapper Component={ImmersoVirtualUniverse} />} />
        <Route path="/media" element={<Wrapper Component={Docs} />} />
        {/* <Route path="/world" element={<Wrapper Component={World} />} /> */}
        <Route path="/the-ecosystem" element={<Wrapper Component={TheEcoSystem} />} />
        <Route path="/immerso-story" element={<Wrapper Component={Lokas} />} />
        <Route
          path="*"
          element={<Wrapper Component={NotFound} />}
        />
        <Route path="/connect" element={<Wrapper Component={Contact} />} />
        <Route path="/subscriber-listing" element={<Wrapper Component={SubscriberListing} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
