/** @format */

import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
//import Logo from "../../assets/Navbar_Footer/Logo.svg";
import { Link, useLocation, NavLink } from "react-router-dom";
import Hamburger from "../../assets/Navbar_Footer/Hamburger.svg";
import Sidebar from "./Sidebar/Sidebar";

const Navbar = ({ imageShow }) => {
  const { pathname } = useLocation();
  const [sidebar, setsidebar] = useState(false);
  const [path, setpath] = useState("");
  const [buttonText, setbuttonText] = useState("Play Now");
  useEffect(() => {
    setpath(pathname.split("/")[1]);
  }, []);
  const HoverButton = () => {
    if (buttonText === "Play Now") {
      setbuttonText("Coming Soon");
    }
    if (buttonText === "Coming Soon") {
      setbuttonText("Play Now");
    }
  };
  return (
    <>
      <header className="site_header">
        <div className="container">
          <div className={classes.Container}>
            {imageShow && (
              <Link to='/' className={classes.Logo}>
                <img src={`${window.location.origin}/assets/img/logo.webp`} alt='Logo' />
              </Link>
            )}
            {/* <div className={classes.Link}>
              <Link
                state={{ animate: false }}
                onClick={() => setpath("home")}
                className={
                  path === "home" ? classes.ActiveLink : classes.InactiveLink
                }
                to='/home'>
                HOME
              </Link>
              <Link
                onClick={() => setpath("about")}
                className={
                  path === "about" ? classes.ActiveLink : classes.InactiveLink
                }
                to='/about'>
                ABOUT
              </Link>
              <Link
                onClick={() => setpath("docs")}
                className={
                  path === "docs" ? classes.ActiveLink : classes.InactiveLink
                }
                to='/docs'>
                DOCS
              </Link>
              <Link
                onClick={() => setpath("team")}
                className={
                  path === "team" ? classes.ActiveLink : classes.InactiveLink
                }
                to='/team'>
                TEAM
              </Link>
              <Link
                onClick={() => setpath("faq")}
                className={path === "faq" ? classes.ActiveLink : classes.InactiveLink}
                to='/faq'>
                FAQ
              </Link>
            </div> */}
            <div className="site_header_menus">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink to="/" className="nav-link">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">About</NavLink>
                  <ul className="dropdown-menu animate slideIn">
                    <li><Link to="/the-ecosystem" className="dropdown-item">The Eco-system</Link></li>
                    <li><Link to="/immerso-virtual-universe" className="dropdown-item">Immerso Multi-Verse</Link></li>
                    {/* <li><Link to="/immerso-story" className="dropdown-item">Immerso Story</Link></li> */}
                    {/* <li><Link to="/faq" className="dropdown-item">FAQ</Link></li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink to="/media" className="nav-link">Media</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/coming-soon" className="nav-link">Team</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/connect" className="nav-link">Connect</NavLink>
                </li>
              </ul>
            </div>
            {imageShow && (
              <div className="site_header_btns d-lg-block d-none">
                {/* <button
                  className="small_btn">
                  Register
                </button> */}
                <button
                  onMouseEnter={HoverButton}
                  onMouseLeave={HoverButton}
                  className="small_btn">
                  {buttonText}
                </button>
                {/* <button
                  onMouseEnter={HoverButton}
                  onMouseLeave={HoverButton}
                  className={classes.ComingSoon}>
                  {buttonText}
                </button> */}
              </div>
            )}
            <div onClick={() => setsidebar(true)} className={classes.HamburgerIcon}>
              <img src={Hamburger} alt='Hamburger' />
            </div>
            <Sidebar sideBar={sidebar} setSideBar={setsidebar} imageShow={imageShow} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
