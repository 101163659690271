import React from 'react';

const SlideItem = ({ slideImage, activeSlide, slideCaption }) => {
    return (
        <div className={`carousel-item ${activeSlide ? " active" : ""}`}>
            <img src={slideImage} className="d-block w-100" alt="SlideImage" />
            {slideCaption ? <div className='slideCaption'>{slideCaption}</div> : null}
        </div>
    )
}

export default SlideItem;