import React, { useEffect, useState } from 'react';
import Banner from '../../Components/Shared/Banner';
import { useForm } from "react-hook-form";
import Loader from '../../Components/Shared/Loader';
import axios from 'axios';
import { Helmet } from "react-helmet";

const SubscriberListing = () => {

    const [showLoader, setShowLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [subscriberList, setSubscriberList] = useState([]);
    const [userLogin, setUserLogin] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm({
        mode: "onTouched"
    });

    const subscriberLogin = async (data) => {

        const { login, password } = data;

        setShowLoader(true);

        if (login === "immerso" && password === "Y6t5r4e3w@q1") {

            setUserLogin(true);

            setShowError(false);

            try {

                let getSubscribers = await axios.get(`${process.env.REACT_APP_IMMERSO_URL}/api/newsletter-list`);

                let getSubscribersData = await getSubscribers;

                if (getSubscribersData.data.success) {

                    setShowLoader(false);

                    setSubscriberList([...getSubscribersData.data.data]);

                }

            } catch (error) {

                setShowLoader(false);

                console.log(error);

            }

        } else {

            setUserLogin(false);

            setShowLoader(false);

            setShowError(true);

            setTimeout(() => {
                setShowError(false);
            }, 10000);
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add("addpageBG");
        window.scrollTo(0, document.body.scrollHeight)
    }, [showError]);

    return (
        <>
            <Helmet>
                <title>Subscriber Listing | IMMERSO</title>
                <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/subscriber-listing`} />
            </Helmet>
            {
                showLoader && <Loader />
            }
            <Banner bgImage="/assets/img/contact_banner.webp" setPosition="center" />
            <section className="section_padding subscriber_listing">
                <div className="container">
                    {
                        !userLogin ? <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="subscriber_listing_authentication">
                                    <h2 className="textHighlightDark text-center mb-5">Please login to view subscribers</h2>
                                    <div className="custom_form_style">
                                        <form onSubmit={handleSubmit(subscriberLogin)}>
                                            <div className="form-group mb-3">
                                                <input type="text" placeholder="Username" className={`form-control${errors.login ? " is-invalid" : ""}`}
                                                    {...register("login", {
                                                        required: true
                                                    })}
                                                />
                                                {errors.login && <div className='invalid-feedback'>This field is required</div>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="password" placeholder="Password" className={`form-control${errors.password ? " is-invalid" : ""}`}
                                                    {...register("password", {
                                                        required: true
                                                    })}
                                                />
                                                {errors.password && <div className='invalid-feedback'>This field is required</div>}
                                            </div>
                                            <div className="form-group text-center">
                                                <button type="submit" className="btn btn-outline-primary">Login</button>
                                            </div>
                                        </form>
                                        {
                                            showError && <div className="alert alert-danger my-3 text-center">Username or Password is incorrect. Please try again</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> : <div className="row">
                            <div className="col-md-12">
                                <h1 className="section_heading font_futura textHighlightDark text-center mb-5">Our Subscribers</h1>
                            </div>
                            <div className="col-lg-8 offset-lg-2">
                                <div className="subscriber_listing_cont scrollingBlock">
                                    <table className="table table-dark table-striped m-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Subscriber email</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subscriberList.map((allElement, indexNo) => {
                                                    return (
                                                        <tr key={indexNo}>
                                                            <td>{indexNo + 1}</td>
                                                            <td>{allElement.email}</td>

                                                            <td>
                                                                {
                                                                    allElement.created_at
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

export default SubscriberListing;