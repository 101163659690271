import React, { useState, useEffect } from 'react';
import Trailer from '../Trailer/Trailer';

const CardCustom = ({ cardImg, cardHeading, cardBtnLinkText, cardBtnLink, comingSoonBtn, runVideo }) => {

    const [videoModal, setvideoModal] = useState(false);

    useEffect(() => {

        if (videoModal) {
            document.body.classList.add("overflow-hidden", "spaceRight");
        } else {
            document.body.classList.remove("overflow-hidden", "spaceRight");
        }

    }, [videoModal])

    return (
        <>
            <div className="cardCustom">
                <div className="cardCustom_image d-flex align-items-center justify-content-center">
                    <img src={cardImg} alt="Litepaper" />
                </div>
                <div className="cardCustom_body d-flex flex-wrap flex-column align-items-center mt-4">
                    <div className="cardCustom_body_heading">
                        <h3 className="text-white mb-0">{cardHeading}</h3>
                    </div>
                    <div className="cardCustom_body_btn mt-4">
                        {comingSoonBtn ? <button className="btn btn-outline-primary comingSoonBtn" onClick={runVideo ? (() => setvideoModal(true)) : null}>
                            <span className="notHover">{cardBtnLinkText}</span>
                            <span className="onHover">Coming Soon</span>
                        </button> : <button className="btn btn-outline-primary" onClick={runVideo ? (() => setvideoModal(true)) : null}>{cardBtnLinkText}</button>}
                    </div>
                </div>
            </div>
            {videoModal && (
                <Trailer showSkipButton={true} displayYTVideo={true} handleClick={() => setvideoModal(false)} />
            )}
        </>
    )
}

export default CardCustom