import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useForm } from "react-hook-form";
import Loader from '../Shared/Loader';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {

    const [alert, setAlert] = useState(false);
    const [recaptchaAlert, setRecaptchaAlert] = useState(false);
    const [recaptchaAlertMsg, setRecaptchaAlertMsg] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [alertMsgError, setAlertMsgError] = useState(false);
    const [alertClass, setAlertClass] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const recaptchaRef = React.createRef();

    const {
        register,
        handleSubmit,
        reset,
        formState: {
            errors
        }
    } = useForm({
        mode: "onTouched"
    });

    function onChange(value) {
        //console.log("Captcha value:", value);
    }

    const contactFormSubmit = async (data) => {

        // const token = await recaptchaRef.current.getValue();
        const token = await recaptchaRef.current.getValue();
        //console.log("recaptchaValue",token);
        if (!token) {
            setShowLoader(false);
            setRecaptchaAlert(true);
            setAlertClass("alert-danger");
            setRecaptchaAlertMsg("Please verify that you're not a robot by solving captcha before submitting.");
            setTimeout(() => {
                setAlertClass("");
                setRecaptchaAlert(false);
                setRecaptchaAlertMsg("");
            }, 10000)

            return false;
        }

        setShowLoader(true);
        try {
            const { firstName, lastName, contactEmail, contactPhone, contactMessage } = data;
            let getData = await axios.post(`${process.env.REACT_APP_IMMERSO_URL}/api/contact-us`, {
                firstName, lastName, contactEmail, contactPhone, contactMessage
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });

            let submittedData = await getData;

            if (submittedData.data.data.status) {
                window.grecaptcha.reset();
                setShowLoader(false);
                reset();
                setAlert(true);
                setAlertMsg(true);
                setAlertClass("alert-success");
                setTimeout(() => {
                    setAlert(false);
                    setAlertMsg(false);
                    setAlertClass("");
                }, 10000);
            }

            window.grecaptcha.reset();

        } catch (error) {
            window.grecaptcha.reset();
            setShowLoader(false);
            setAlert(true);
            setAlertMsgError(true);
            setAlertClass("alert-danger");
            setTimeout(() => {
                setAlert(false);
                setAlertMsgError(false);
                setAlertClass("");
            }, 10000)
            console.log(error);
        }
    }

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight)
    }, [alertMsg, alertMsgError, recaptchaAlertMsg])

    return (
        <>
            {
                showLoader && <Loader />
            }
            <div className='custom_form_style'>
                <form onSubmit={handleSubmit(contactFormSubmit)}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group mb-3'>
                                <input type="text"
                                    className={`form-control${errors.firstName ? " is-invalid" : ""}`} placeholder="First Name"
                                    {...register("firstName", {
                                        required: "This field is required",
                                        pattern: {
                                            value: /[^\s]+/,
                                            message: "Empty spaces are not allowed"
                                        }
                                    })}
                                />
                                {errors.firstName && <div className='invalid-feedback'>{errors.firstName.message}</div>}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group mb-3'>
                                <input type="text" className={`form-control${errors.lastName ? " is-invalid" : ""}`} placeholder="Last Name"
                                    {...register("lastName", {
                                        required: "This field is required",
                                        pattern: {
                                            value: /[^\s]+/,
                                            message: "Empty spaces are not allowed"
                                        }
                                    })}
                                />
                                {errors.lastName && <div className='invalid-feedback'>{errors.lastName.message}</div>}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group mb-3'>
                                <input type="email" className={`form-control${errors.contactEmail ? " is-invalid" : ""}`} placeholder="Email"
                                    {...register("contactEmail", {
                                        required: "This field is required",
                                        pattern: {
                                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                            message: "Please enter a valid email Address"
                                        }
                                    })}
                                />
                                {errors.contactEmail && <div className='invalid-feedback'>{errors.contactEmail.message}</div>}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group mb-3'>
                                <input type="number" className={`form-control${errors.contactPhone ? " is-invalid" : ""}`} placeholder="Phone"
                                    {...register("contactPhone")}
                                />
                                {errors.contactPhone && <div className='invalid-feedback'>{errors.contactPhone.message}</div>}
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='form-group mb-3'>
                                <textarea className={`form-control${errors.contactMessage ? " is-invalid" : ""}`} placeholder="Message" rows="6"
                                    {...register("contactMessage", {
                                        required: "This field is required",
                                        pattern: {
                                            value: /[^\s]+/,
                                            message: "Empty spaces are not allowed"
                                        },
                                        maxLength: 300
                                    })}
                                ></textarea>
                                {errors.contactMessage && <div className='invalid-feedback'>{errors.contactMessage.message}</div>}

                                {errors.contactMessage && errors.contactMessage.type === "maxLength" && <div className='invalid-feedback'>Maximum 300 characters are allowed.</div>}

                            </div>
                        </div>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdsdachAAAAAJdmLEJ-JGdPeLyeav7qqTedxOoi"
                            onChange={onChange}
                            className="recaptcha_outer"
                        />
                        <div className='col-md-12'>
                            <div className='form-group pt-4 text-center'>
                                <button type="submit" className='btn btn-outline-primary'>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
                {alert && alertMsg && <div className={`alert mt-4 text-center ${alertClass}`} >Thank you for contacting us. We will get back to you soon.</div>}
                {alert && alertMsgError && <div className={`alert mt-4 text-center ${alertClass}`} >Something went wrong. Please try again.</div>}
                {recaptchaAlert && <div className={`alert mt-4 text-center ${alertClass}`} >{recaptchaAlertMsg}</div>}
            </div>
        </>
    )
}

export default ContactForm