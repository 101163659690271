/** @format */

import Atala from "../../assets/CTAs/Modal/Atala.png";
import Bhurloka from "../../assets/CTAs/Modal/Bhurloka.png";
import Bhuvarloka from "../../assets/CTAs/Modal/Bhuvarloka.png";
import JanaLoka from "../../assets/CTAs/Modal/JanaLoka.png";
import Maharloka from "../../assets/CTAs/Modal/Maharloka.png";
import Mahatala from "../../assets/CTAs/Modal/Mahatala.png";
import Patala from "../../assets/CTAs/Modal/Patala.png";
import Rastala from "../../assets/CTAs/Modal/Rastala.png";
import Satyaloka from "../../assets/CTAs/Modal/Satyaloka.png";
import Sutala from "../../assets/CTAs/Modal/Sutala.png";
import Svarloka from "../../assets/CTAs/Modal/Svarloka.png";
import Talatala from "../../assets/CTAs/Modal/Talatala.png";
import TapaLoka from "../../assets/CTAs/Modal/TapaLoka.png";
import Vitala from "../../assets/CTAs/Modal/Vitala.png";

import Atala_Phone_Modal from "../../assets/CTAs/Phone/Atala.png";
import Bhurloka_Phone_Modal from "../../assets/CTAs/Phone/Bhurloka.png";
import Bhuvarloka_Phone_Modal from "../../assets/CTAs/Phone/Bhuvarloka.png";
import JanaLoka_Phone_Modal from "../../assets/CTAs/Phone/Janaloka.png";
import Maharloka_Phone_Modal from "../../assets/CTAs/Phone/Maharloka.png";
import Mahatala_Phone_Modal from "../../assets/CTAs/Phone/Mahatala.png";
import Patala_Phone_Modal from "../../assets/CTAs/Phone/Patala.png";
import Rastala_Phone_Modal from "../../assets/CTAs/Phone/Rastala.png";
import Satyaloka_Phone_Modal from "../../assets/CTAs/Phone/Satyaloka.png";
import Sutala_Phone_Modal from "../../assets/CTAs/Phone/Sutala.png";
import Svarloka_Phone_Modal from "../../assets/CTAs/Phone/Svarloka.png";
import Talatala_Phone_Modal from "../../assets/CTAs/Phone/Talatala.png";
import TapaLoka_Phone_Modal from "../../assets/CTAs/Phone/Tapaloka.png";
import Vitala_Phone_Modal from "../../assets/CTAs/Phone/Vitala.png";

export const data = {
  Atala,
  Bhuvarloka,
  Bhurloka,
  JanaLoka,
  Maharloka,
  Mahatala,
  Patala,
  Rastala,
  Satyaloka,
  Sutala,
  Svarloka,
  Talatala,
  TapaLoka,
  Vitala,
};

export const Phone_Modal = {
  Atala_Phone_Modal,
  Bhurloka_Phone_Modal,
  Bhuvarloka_Phone_Modal,
  JanaLoka_Phone_Modal,
  Maharloka_Phone_Modal,
  Mahatala_Phone_Modal,
  Patala_Phone_Modal,
  Rastala_Phone_Modal,
  Satyaloka_Phone_Modal,
  Sutala_Phone_Modal,
  Svarloka_Phone_Modal,
  Talatala_Phone_Modal,
  TapaLoka_Phone_Modal,
  Vitala_Phone_Modal,
};

export const BharmandPopupData = {
  satyalokData: {
    "heading": "Satyaloka (Highest)",
    "description": "This realm is also known as Brahmaloka. This is where Brahma, the God of all creation resides in the realm full of eternal gardens.",
    "img": "assets/img/popupImg.webp"
  },
  tapalokaData: {
    "heading": "Tapaloka",
    "description": "Tapaloka is the abode of the 4 Kumaras – the advanced imoortal beings. They reside in Tapovan, the spiritual forest.",
    "img": "assets/img/tapaloka.webp"
  },
  janalokaData: {
    "heading": "Janaloka",
    "description": "The nine sons of Brahma live here, Manasputra/Prajapati: Progenitors of the demigods. The vast lands are covered with dense and picturesque cosmic jungles and rivers of amrit.",
    "img": "assets/img/janaloka.webp"
  },
  maharlokaData: {
    "heading": "Maharloka",
    "description": "The realm of the Saptarishi, the seven extoled sages. A cliff laden realm with occeans of milk.  ",
    "img": "assets/img/maharloka_popup.webp"
  },
  swarglokaData: {
    "heading": "Swargaloka",
    "description": "The cloud realm of Devas full of floating Palaces. Indra the lord of the demigods resides here along with his 33 devas and other spirits: Gandharvas, Apsaras, Yakshas – Devi’s, Marutas",
    "img": "assets/img/swargaloka.webp"
  },
  bhuvarlokaData: {
    "heading": "Bhuvarloka",
    "description": "A multi landscaped realm occupied by semi-divine beings who are a part of the human world as well. The universe rotates around the pole star connected via astral cords within this realm.",
    "img": "assets/img/bhuvarloka.webp"
  },
  bhurlokaData: {
    "heading": "Bhuloka (central)",
    "description": "the 7th plane of existence where human beings live along with all the other species of life. Avatars of Lord Vishnu reside year over the cycles of creation.",
    "img": "assets/img/bhuloka_popup.webp"
  },
  atalaData: {
    "heading": "Atala",
    "description": "The Underwater realm full of vanity, opulence, seduction. The master of the mystic arts, Bal Son of the Asura Mayasura rules this realm",
    "img": "assets/img/atala.webp"
  },
  vitalaData: {
    "heading": "Vitala",
    "description": "The realm of miners that scour the inverted mountains in the sky for Gold and Jewels. Many beings from the lower realms come here to worship Shiva at the Hatkeswar temple.",
    "img": "assets/img/vitala.webp"
  },
  sutalaData: {
    "heading": "Sutala",
    "description": "the realm of the demon king Bali, an Earthly king banished to Sutala by an avatar of Vishnu. A swampy marshland covered with mounds with the sun perpetually eclipsed.",
    "img": "assets/img/sutala.webp"
  },
  talatalaData: {
    "heading": "Talatala",
    "description": "The realm of Mayasura, and his celestial engineers. Jagged rocks break the barren lands here, and the sun barely shines through the thick fog and fumes.",
    "img": "assets/img/talatala_popup.webp"
  },
  mahatalaData: {
    "heading": "Mahatala",
    "description": "This realm is inhabited by Kadru’s Naga sons (serpents), it is an underground realm of a maze of tunnels. A river runs underneath the subterranean realm",
    "img": "assets/img/mahatala.webp"
  },
  rastalaData: {
    "heading": "Rastala",
    "description": "Danu rules this realm of the Danavas and Daityas, enemies of the Devas. Spiked mountains tear the lands that are covered in a poisonous fume.",
    "img": "assets/img/rasatala.webp"
  },
  patalaData: {
    "heading": "Patala (Lowest)",
    "description": "The deepest realm representative of hell is also called Nagaloka. Rivers of lava flow across the lands where a large multiheaded serpent lies coiled on top of an underground mountain.",
    "img": "assets/img/patala.webp"
  }
}
