/** @format */

import React, { useEffect, useState } from "react";
import classes from "./Common.module.css";
import { data as LokasData, BharmandPopupData } from "./data";
//import PhoneDiscs from "./PhoneDiscs";
// import HoverRing from "../../assets/CTAs/Hover_Ring.png";
// import YellowRing from "../../assets/CTAs/YellowRing.png";
//import Space from "../../Components/space/Space";
import Banner from "../../Components/Shared/Banner";
import { Helmet } from "react-helmet";

const Lokas = () => {
  const [showModal, setshowModal] = useState(false);
  const [modalData, setmodalData] = useState(null);

  useEffect(() => {
    document.body.classList.add("addpageBG");
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // window.scrollTo(0, 0);
  }, [showModal]);

  const HoverRing = "assets/img/blue_ring.svg";
  const YellowRing = "assets/img/yellow_ring.svg";

  const openModal = (text) => {
    setshowModal(true);
    // setmodalData(LokasData[text]);
    setmodalData(BharmandPopupData[text]);
  };
  const closeModal = () => {
    setshowModal(false);
  };

  const setBtnOffset = () => {

    const popup_custom = setInterval(() => {
      let isLoded = false;
      if (document.getElementById("popup_cont_inner")) {
        document.getElementById('popupContImg').addEventListener('load', () => {
          isLoded = true;
          let btnClose = document.getElementById("popup_btnClose");

          let popupInnerCont = document.getElementById("popup_cont_inner");

          let getBtnTopSpace = popupInnerCont.offsetTop;

          btnClose.style.top = `calc(${getBtnTopSpace}px - 15px)`;

          clearInterval(popup_custom);
        })
        if (!isLoded) {

          let btnClose = document.getElementById("popup_btnClose");

          let popupInnerCont = document.getElementById("popup_cont_inner");

          let getBtnTopSpace = popupInnerCont.offsetTop;

          btnClose.style.top = `calc(${getBtnTopSpace}px - 15px)`;

          clearInterval(popup_custom);

        }
      }


    }, 100);
  }
  return (
    <>
      <Helmet>

        <title>Immerso - Immerso Story</title>

        <link rel="canonical" href={`${process.env.REACT_APP_IMMERSO_LIVE_URL}/immerso-story`} />

        <meta name="description" content="Witness the story of God, Samudra Manthan, Asuras and Devas and more within the virtual universe of Immerso." />

        <meta property="og:url" content="https://immerso.be/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Immerso - Immerso Story" />
        <meta property="og:description" content="Witness the story of God, Samudra Manthan, Asuras and Devas and more within the virtual universe of Immerso." />
        <meta property="og:image" content="https://immerso.be/assets/img/logo.webp"></meta>

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="immerso.be" />
        <meta property="twitter:url" content="https://immerso.be/" />
        <meta name="twitter:title" content="Immerso - Immerso Story" />
        <meta name="twitter:description" content="Witness the story of God, Samudra Manthan, Asuras and Devas and more within the virtual universe of Immerso." />
        <meta name="twitter:image" content="https://immerso.be/assets/img/logo.webp"></meta>

      </Helmet>
      <Banner bgImage={"assets/img/about_page_banner.webp"} joinImmerso={false} setPosition="center" />

      <section className="about_two_column_section section_padding colorOffWhite">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about_two_column_left">
                <div className="about_two_column_left_heading text-center">
                  <h1 className="section_heading textHighlightDark">Lokas</h1>
                  <p className="mb-5">There are 14 Realms (lokas) in the universe. Earth is in the central realm, Bhuloka. Each realm has several planetary systems. The realms above the central are more spiritually inclined and the ones below are more materialistic. Time moves at a different speed in all the realms.</p>
                  <p className="mb-0"><span className="textHighlightDark fw-medium">Click on each ring to see what each Loka has in store for you.</span></p>
                </div>
                <div className="brahmand_layers addOvalBg mt-4 d-table mx-auto">
                  <div className={classes.Discs}>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("satyalokData"); setBtnOffset() }}
                        height={"34px"}
                        width={"228px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("satyalokData"); setBtnOffset() }}
                        className={classes.LokaText}>
                        SATYALOKA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("tapalokaData"); setBtnOffset() }}
                        height={"34px"}
                        width={"266px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p onClick={() => { openModal("tapalokaData"); setBtnOffset() }} className={classes.LokaText}>
                        TAPALOKA
                      </p>
                    </div>

                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("janalokaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"299px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p onClick={() => { openModal("janalokaData"); setBtnOffset() }} className={classes.LokaText}>
                        JANALOKA
                      </p>
                    </div>

                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("maharlokaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"336px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("maharlokaData"); setBtnOffset() }}
                        className={classes.LokaText}>
                        MAHARLOKA
                      </p>
                    </div>

                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("swarglokaData"); setBtnOffset() }}
                        height={"46px"}
                        width={"378px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p onClick={() => { openModal("swarglokaData"); setBtnOffset() }} className={classes.LokaText}>
                        SWARGLOKA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("bhuvarlokaData"); setBtnOffset() }}
                        height={"53px"}
                        width={"429px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("bhuvarlokaData"); setBtnOffset() }}
                        className={classes.LokaText}>
                        BHUVARLOKA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("bhurlokaData"); setBtnOffset() }}
                        height={"53px"}
                        width={"481px"}
                        src={HoverRing}
                        alt='HoverRing'
                      />
                      <p onClick={() => { openModal("bhurlokaData"); setBtnOffset() }} className={classes.LokaText}>
                        BHULOKA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("atalaData"); setBtnOffset() }}
                        height={"53px"}
                        width={"429px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("atalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        ATALA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("vitalaData"); setBtnOffset() }}
                        height={"46px"}
                        width={"378px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("vitalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        VITALA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("sutalaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"338px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("sutalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        SUTALA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("talatalaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"300px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("talatalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        TALATALA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("mahatalaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"266px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("mahatalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        MAHATALA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("rastalaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"228px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("rastalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        RASTALA
                      </p>
                    </div>
                    <div className={classes.Disc}>
                      <img
                        onClick={() => { openModal("patalaData"); setBtnOffset() }}
                        height={"35px"}
                        width={"204px"}
                        src={YellowRing}
                        alt='HoverRing'
                      />
                      <p
                        onClick={() => { openModal("patalaData"); setBtnOffset() }}
                        className={classes.LokaText}
                        style={{ color: "#CFB370" }}>
                        PATALA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_two_column_right mt-lg-0 mt-5">
                <div className="heading_inBox">
                  <h2 className="m-0">Immerso: Story</h2>
                </div>
                <div className="scrollingBlock mt-4 content_section">

                  <p>It all began with the trinity of the Elder Gods: Brahma, The Creator. Vishnu, The Preserver. Shiva, The Destroyer. Brahma created the universe and its realms filling them with all forms of life within it. Vishnu through his avatars protects the foundations on which the universe is built, Dharma. A Celestial law that constitutes duties, rights, conduct, virtues and "right way of living" within these worlds. When the realms are saturated with Adharma (discord, disharmony, unnaturalness, wrongness, evil, immorality, unrighteousness, wickedness, and vice) Shiva destroys these worlds and another cycle of creation begins. With every cycle, lifeforms evolve but the cause of their downfall is always the same. </p>

                  <p>Brahma created the first celestials through whom came about the clans of demigods. They were bestowed with superhuman powers and abilities to protect lesser beings while maintaining order in the realms. In turn they were worshipped by these beings. Beauty and order governed the universe in harmony for a time.  </p>

                  <p>Until, the two main clans of the demigods, Asuras and Devas, stepbrothers began having conflicts. The Asuras driven by pride and greed began using their power for personal gain manipulating weaker beings in order to rule worlds as their own personal kingdoms. Mortals appealed to the Devas for help who opposed the Asuras and their dictatorship as their Dharma to uphold balance in the world. Thus began the eternal battle between the two clans. The trinity intervened imposing sanctions on the Asuras, taking away their immortality thus making them even more rebellious. The Asuras found a way to resurrect themselves drawing from the dark powers of the universe. Wars continued to rage in the Brahmand as the Asuras began to invade realms of the Devas. Darkness crept in the world like poison spreading violence across mortals as they began waging their own wars, contaminating the entire universe. </p>

                  <p>There came but one chance for peace, ‘Samudra Manthan’ where the Asuras and Devas, as directed by the trinity churned the ocean for magical treasures. One of these gifts being, Amrit(Nectar of the immortality) But it was never the intention of the Asurans to find a truce. Instead, they schemed to use their regained immortality to attack with even greater force. The trinity saw through their intentions and ensured the Asuras never tasted a drop the elixir enraging them even further.</p>

                  <p>Eons of battles continued, worlds began to decay, populations obliterated, lifespans reduced. Children and mothers died in childbirth. People were born with deformities, weaknesses, debilitating conditions. There seemed to be no end to the curses that were brought on. The Trinity had enough! Finally, the elder god Shiva faced the Asura’s single-handedly, duplicating himself and embodying the Trinity itself, driving them into the lower realms, where they were given dominion. </p>

                  <p>The Asuras ruled the lower realms guarding their unfathomable material wealth. Mortals resided in the central realms, and the Devas took their place in the upper realms. Portals were created within certain realms and travel between them was regulated. Bhuloka (The central realm) housed the portals through which all inter realm travel was routed. </p>

                  <p>However, the Asuras using their devious ways found a way to infiltrate the portals on earth. Earth became a battleground for these clans over the control of these gateways. Mankind was pulled in both directions and sides were drawn. Some bound by blood and duty, others by matrimony and political advantage. Thousands of clans began to form as generations continued to be wiped out on every side. Asura’s almost succeeded and all that was left at the end was ash, blood, and tears. Eventually, the portals were sealed, the realms cut off. A semblance of peace was finally found. Until eons later when one open portal threatens to change everything… </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showModal && <ModalNew closeModal={closeModal} popupCont={modalData} />}

      {/* <div className={classes.Container}>
        <div className={classes.Info}>
          Click on each ring to see what each Loka has in store for you.
        </div>
        <Space />
        <Space />
        <Space />
        <Space />
        <div className={classes.Discs}>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Satyaloka")}
              height={"34px"}
              width={"228px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Satyaloka")}
              className={classes.LokaText}>
              SATYALOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("TapaLoka")}
              height={"34px"}
              width={"266px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p onClick={() => openModal("TapaLoka")} className={classes.LokaText}>
              TAPALOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Maharloka")}
              height={"35px"}
              width={"299px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Maharloka")}
              className={classes.LokaText}>
              MAHARLOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("JanaLoka")}
              height={"35px"}
              width={"336px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p onClick={() => openModal("JanaLoka")} className={classes.LokaText}>
              JANALOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Svarloka")}
              height={"46px"}
              width={"378px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p onClick={() => openModal("Svarloka")} className={classes.LokaText}>
              SWARGLOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Bhuvarloka")}
              height={"53px"}
              width={"429px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Bhuvarloka")}
              className={classes.LokaText}>
              BHUVARLOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Bhurloka")}
              height={"53px"}
              width={"481px"}
              src={HoverRing}
              alt='HoverRing'
            />
            <p onClick={() => openModal("Bhurloka")} className={classes.LokaText}>
              BHURLOKA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Atala")}
              height={"53px"}
              width={"429px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Atala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              ATALA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Vitala")}
              height={"46px"}
              width={"378px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Vitala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              VITALA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Sutala")}
              height={"35px"}
              width={"338px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Sutala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              SUTALA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Talatala")}
              height={"35px"}
              width={"300px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Talatala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              TALATALA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Mahatala")}
              height={"35px"}
              width={"266px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Mahatala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              MAHATALA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Rastala")}
              height={"35px"}
              width={"228px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Rastala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              RASTALA
            </p>
          </div>
          <div className={classes.Disc}>
            <img
              onClick={() => openModal("Patala")}
              height={"35px"}
              width={"204px"}
              src={YellowRing}
              alt='HoverRing'
            />
            <p
              onClick={() => openModal("Patala")}
              className={classes.LokaText}
              style={{ color: "#CFB370" }}>
              PATALA
            </p>
          </div>
        </div>
        <PhoneDiscs />
        {showModal && <Modal closeModal={closeModal} image={modalData} />}
        
      </div> */}
    </>
  );
};

// const Modal = ({ image, closeModal }) => {
//   return (
//     <div className={classes.OuterContainers}>
//       <div className={classes.ModalContainer}>
//         <img draggable={false} src={image} alt='modal ' />
//         <div onClick={closeModal} className={classes.Close}>
//           +
//         </div>
//       </div>
//     </div>
//   );
// };

const ModalNew = ({ popupCont, closeModal }) => {
  return (
    <>
      <div className="popup_brahmandLayer popup_custom" id="popup_custom">
        <div className="modal-dialog">
          <div className="popup_cont">
            <button className="btn btn-close" onClick={closeModal} id="popup_btnClose"><i className="material-symbols-outlined">close</i></button>
            <div className="popup_cont_inner" id="popup_cont_inner">
              <div className="popup_cont_img">
                <img src={popupCont.img} alt="Layer_image" id="popupContImg" />
              </div>
              <div className="popup_cont_body colorOffWhite">
                <h3 className="textHighlightDark">{popupCont.heading}</h3>
                <p>{popupCont.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Lokas;
